import { ImgList } from "../../../common/data";
import ShowCaseLargeDevice from "../reusable-components/ShowCaseLargeDevice";
import ShowCaseSmallDevice from "../reusable-components/ShowCaseSmallDevice";

function MpShowCase() {
  return (
    <section id="show-case">
      <div className="container">
        <div className="row small-hide">
          {ImgList.map((item) => {
            return (
              <ShowCaseLargeDevice
                key={item.id}
                Img={item.img}
                Alt={item.alt}
              />
            );
          })}
        </div>
        <div className="row large-hide">
          <div className="col-12">
            <div
              id="carouselExampleInterval"
              className="carousel slide d-flex justify-content-center"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner w-75">
                {ImgList.map((item) => {
                  return (
                    <ShowCaseSmallDevice
                      key={item.id}
                      Img={item.img}
                      Alt={item.alt}
                    />
                  );
                })}
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="visually-hidden">Next</span>
                        </button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpShowCase;
