import { Link } from "react-router-dom";
import BlogCard from "../reusable-components/BlogCard";
import { BlogDataApi } from "../../../api/ApiCalls";
import { useEffect, useState } from "react";
import { BlogDataType } from "../../../common/dataType";

function MpBlog() {
  const [blogData, setBlogData] = useState<BlogDataType[]>([]);

  async function getBlogData() {
    const result = await BlogDataApi();

    const parsedResult = JSON.parse(result);

    return parsedResult;
  }

  useEffect(() => {
    getBlogData().then((data) => setBlogData(data));
  }, []);

  return (
    <section id="blog">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 my-2 mb-4">
            <h3 className="text-center text-light">
              <b>Our Recent Blog</b>
            </h3>
          </div>

          {/* <!--col-repeat start--> */}

          {blogData.map((item, index) => {
            return (
              <BlogCard
                key={index}
                blogLink={item.blog_link}
                blogTitle={item.blog_title}
                blogImage={item.img_path}
              />
            );
          })}

          {/* <div className="col-md-3 my-2">
            <Link
              className="nav-link"
              to="https://blog.vyomscode.com/index.php/2024/03/11/vyomscode-work-process/"
            >
              <div className="card h-100">
                <div className="card-head">
                  <img
                    src="/assets/blog1.png"
                    className="w-100 rounded-top"
                    alt="blog"
                  />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light mb-0">
                    Vyomscode Work Process
                  </h5>
                  <br />
                </div>
              </div>
            </Link>
          </div
          {/* <!--col-repeat end--> 

          <div className="col-md-3 my-2">
            <a
              className="nav-link"
              href="https://blog.vyomscode.com/index.php/2024/03/13/elevate-your-digital-presence-with-vyomscode/"
            >
              <div className="card h-100">
                <div className="card-head">
                  <img
                    src="/assets/blog2.svg"
                    className="w-100 rounded-top"
                    alt="blog"
                  />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light mb-0">
                    Elevate Your Digital Presence with VyomsCode
                  </h5>
                </div>
              </div>
            </a>
          </div>

          <div className="col-md-3 my-2">
            <a
              className="nav-link"
              href="https://blog.vyomscode.com/index.php/2024/03/15/navigating-the-citizenship-amendment-act-caa-a-nuanced-exploration/"
            >
              <div className="card h-100">
                <div className="card-head">
                  <img
                    src="/assets/blog3.jpeg"
                    className="w-100 rounded-top"
                    alt="blog"
                  />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light mb-0">
                    Navigating the Citizenship Amendment Act (CAA)
                  </h5>
                </div>
              </div>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default MpBlog;
