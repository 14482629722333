import { servicesData } from "../../../common/data";
import ServicesLargeDevice from "../reusable-components/ServicesLargeDevice";
import ServicesSmallDevice from "../reusable-components/ServicesSmallDevice";

function MpServices() {
  return (
    <section id="services">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 py-2">
            <h2 className="text-center text-light">
              <b>OUR SERVICES</b>
            </h2>
          </div>
          <div className="col-12 mb-3">
            <p className="text-center text-light">
              VyomsCode offers a comprehensive suite of digital services to
              elevate your online presence. From crafting bespoke websites with
              responsive design and SEO optimization to delivering captivating
              graphic design and branding solutions, we ensure your brand stands
              out in the digital landscape.
              {/* <!-- Our expertise extends to developing custom applications tailored to your needs, providing seamless user experiences and robust functionality. -->
                          <!-- With VyomsCode, empower your digital strategy and unlock your full potential online. --> */}
            </p>
          </div>
        </div>
        <div className="row small-hide">
          {servicesData.map((item) => {
            return (
              <ServicesLargeDevice
                key={item.id}
                title={item.title}
                img={item.img}
                goToLink={item.goToLink}
              />
            );
          })}
        </div>

        <div className="row large-hide">
          <div className="col-12">
            <div
              id="carouselExampleInterval3"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {servicesData.map((item) => {
                  return (
                    <ServicesSmallDevice
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      img={item.img}
                      goToLink={item.goToLink}
                    />
                  );
                })}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleInterval3"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleInterval3"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpServices;
