import { Route, Routes } from "react-router-dom";
import PageNotFound from "../common-components/PageNotFound";
import WebsiteDevelopment from "./ServiceSubPages/WebsiteDevelopment";
import GraphicDesigning from "./ServiceSubPages/GraphicDesigning";
import AppDevelopment from "./ServiceSubPages/AppDevelopment";
import DigitalMarketing from "./ServiceSubPages/DigitalMarketing";

function ServicesHome() {
  return (
    <Routes>
      <Route path="/" element={<PageNotFound />} />
      <Route path="/website-development" element={<WebsiteDevelopment />} />
      <Route path="/graphic-designing" element={<GraphicDesigning />} />
      <Route path="/app-development" element={<AppDevelopment />} />
      <Route path="/digital-marketing" element={<DigitalMarketing />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
}

export default ServicesHome;
