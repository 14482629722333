import { Link } from "react-router-dom";
import CharityImg from '../../../assets/project-img/charity.webp'

function AppWork() {
  return (
    <section
      id="app"
      style={{ backgroundColor: "rgb(8, 64, 83)", display: "none" }}
    >
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Application Devlopment Projets</b>
            </h3>
            <p className="text-light text-center">
              Websites Using HTML, CSS, JS, JQuery, Bootstrap, PHP, Laravel,
              React, Angular
            </p>
            <h6 className="text-center">
              <Link to="/services/app-devlopment.html" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>

          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-head">
                <img
                  src={CharityImg}
                  className="card-img-top rounded-top"
                  alt=""
                />
              </div>
              <div className="card-body">
                <h5 className="text-light text-center">Nonprofit Websites</h5>
                <p className="text-light text-center">
                  Frontend project using HTML, CSS, JS, Bootstrap . This project
                  is an informational website for Charitable Organizations.
                </p>
              </div>
              <div className="card-footer">
                <h5 className="text-center">
                  <Link to="">
                    <button className="btn btn-warning">View Project</button>
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppWork;
