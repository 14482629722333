
function AdAppProjects() {
  return (
    <section id="app-projects" style={{ display: "none" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 ">
              <h3 className="text-center text-light">
                APP DEVLOPMENT PROJECTS
              </h3>
              <p className="text-center text-light mb-4">
                With expertise across multiple frontend and backend frameworks,
                we've successfully delivered diverse projects tailored to
                various industries. From React, Angular, Flask, and Node.js,
                we're equipped to bring your vision to life
              </p>
            </div>
            <div className="col-md-3 my-2">
              <div className="card h-100 bg-purple">
                <div className="card-header">
                  <img src="" className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light"></h5>
                  <p className="text-center text-light"></p>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2">
              <div className="card h-100 bg-purple">
                <div className="card-header">
                  <img src="" className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light"></h5>
                  <p className="text-center text-light"></p>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2">
              <div className="card h-100 bg-purple">
                <div className="card-header">
                  <img src="" className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light"></h5>
                  <p className="text-center text-light"></p>
                </div>
              </div>
            </div>
            <div className="col-md-3 my-2">
              <div className="card h-100 bg-purple">
                <div className="card-header">
                  <img src="" className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="text-center text-light"></h5>
                  <p className="text-center text-light"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AdAppProjects