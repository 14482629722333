import { Link } from "react-router-dom";

import Flex1Img from "../../../assets/project-img/flex1.svg";
import Flex2Img from "../../../assets/project-img/flex2.svg";
import Flex3Img from "../../../assets/project-img/flex3.svg";

import { GraphicDesignData, GraphicUIUXData } from "../../../common/data";
import GraphicDesignSocialMediaCard from "../../reusables/GraphicDesignSocialMediaCard";
import GraphicDesignUiUxCard from "../../reusables/GraphicDesignUiUxCard";

function GraphicWork() {
  return (
    <section id="graphic" style={{ backgroundColor: "rgb(8, 64, 83)" }}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Graphic Design Projets</b>
            </h3>
            <p className="text-light text-center">
              Graphic Design Using Canva, Inkspace, figma.
            </p>
            <h6 className="text-center">
              <Link to="/services/graphic-designing" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>

          {GraphicDesignData.map((item) => {
            return (
              <GraphicDesignSocialMediaCard
                key={item.id}
                graphicTitle={item.graphicTitle}
                graphicImage={item.graphicImage}
                graphicImageAlt={item.graphicImageAlt}
              />
            );
          })}

          <div className="col-md-12 mb-1"></div>

          {GraphicUIUXData.map((item) => {
            return (
              <GraphicDesignUiUxCard
                key={item.id}
                graphicTitle={item.graphicTitle}
                graphicImage={item.graphicImage}
                graphicImageAlt={item.graphicImageAlt}
              />
            );
          })}

          <div className="col-md-12 mb-1"></div>

          <div className="col-md-9 my-1">
            <div className="card h-100  bg-blue">
              <div className="card-head">
                <img
                  //   src={Flex1Img}
                  className="card-img-top rounded-top"
                  alt="Flex Board design for school (our client)"
                />
              </div>
              <div className="card-body">
                <p className="text-center mb-0 text-light">
                  Flex Board design for school (our client)
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100  bg-blue">
              <div className="card-head">
                <img
                  //   src={Flex2Img}
                  className="card-img-top rounded-top"
                  alt="Flex Board design for school (our client)"
                />
              </div>
              <div className="card-body">
                <p className="text-center mb-0 text-light">
                  Flex Board design for school (our client)
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100  bg-blue">
              <div className="card-head">
                <img
                  //   src={Flex3Img}
                  className="card-img-top rounded-top"
                  alt="Flex Board design for school (our client)"
                />
              </div>
              <div className="card-body">
                <p className="text-center mb-0 text-light">
                  Flex Board design for school (our client)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GraphicWork;
