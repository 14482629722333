function Footer() {
  return (
    <footer id="footer" className="bg-dark text-light">
      <div className="container">
        <div className="row">
          <div className="col justify-content-center d-flex py-3">
            {/* <p className="text-center mb-0">Copyright © 2023 by tgeekcode all Rights Reserved.</p> */}
            <p className="text-center mb-0"> VyomsCode 2024</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
