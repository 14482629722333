import GoToTopButton from "../../common-components/GoToTopButton";
import DmAboutDigital from "./digital-marketing-subcomponents/DmAboutDigital";
import DmPageHome from "./digital-marketing-subcomponents/DmPageHome";

function DigitalMarketing() {
  return (
    <div>
      <DmPageHome />

      <DmAboutDigital />
    </div>
  );
}

export default DigitalMarketing;
