import { useEffect, useState } from "react";

function MpPageHome() {
  const words = [
    "WEBSITE",
    "APPLICATIONS",
    "LOGOS",
    "SOCIAL MEDIA POST",
    "SEO",
    "DIGITAL MARKETING",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const [showWord, setShowWord] = useState("");
  // const [typing, setTyping] = useState(true); // added a new state to track typing status

  // console.log("words")
  // console.log(words[1])

  function typeWriter() {
    if (currentIndex >= words.length) {
      setCurrentIndex(0);
    }
    let currentWord = words[currentIndex];
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < currentWord.length) {
        setShowWord(currentWord.substring(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
        // setTyping(false); // set typing to false when done
        setTimeout(() => {
          eraseWord();
        }, 500);
      }
    }, 100);
  }

  function eraseWord() {
    const currentWord = words[currentIndex];
    let i = currentWord.length - 1;
    const erasingInterval = setInterval(() => {
      if (i >= 0) {
        setShowWord(currentWord.substring(0, i));
        i--;
      } else {
        clearInterval(erasingInterval);
        // setTyping(true); // set typing to true when erasing is done
        setCurrentIndex(currentIndex + 1); // increment index after erasing
        setTimeout(() => typeWriter(), 500);
      }
    }, 100);
  }

  useEffect(() => {
    return () => {
      typeWriter();
    };
  }, []);

  return (
    <section id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-sm-12 py-5 d-flex justify-content-center  mt-md-5">
            <div className="card-rotate-1 ">
              <h1 className="lh-md text-center text-light">
                <strong>
                  Stand Out With VyomsCode <br className="break" />
                  Your One-Stop Destination For <br />
                  {/* <!-- unique and creative --> */}
                  <span className="text-change" id="typing">
                    {showWord}
                  </span>
                  {/* <!-- WEBSITE APPLICATIONS LOGOS SOCIAL MEDIA POST SEO DIGITAL MARKETING --> */}
                </strong>
              </h1>
              <p className="text-light text-center">
                Unlock Your Digital Potential
              </p>
              <div className="d-flex justify-content-center">
                <a className="nav-link" href="/#services">
                  <button className="btn btn-outline-light  mx-1">
                    Services
                  </button>
                </a>
                <a className="nav-link" href="#our-work">
                  <button className="btn btn-outline-light  mx-1">
                    &nbsp;&nbsp;Work&nbsp;&nbsp;
                  </button>
                </a>
                <a className="nav-link" href="#about-us">
                  <button className="btn btn-outline-light  mx-1">
                    About us
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpPageHome;
