function ContactUs() {
  // const form = document.querySelector("#form");
  // const submitButton = document.querySelector("#submit");
  // const scriptURL =
  //   "https://script.google.com/macros/s/AKfycbw2CAC9HCjlGMspIpeunfox00QUK8lFR-VqS1oJIQDr2FeESuliYNjVARnq2mUOTi5E/exec";

  // form!.addEventListener("submit", (e) => {
  //   submitButton!.disabled = true;
  //   e.preventDefault();
  //   let requestBody = new FormData(form);
  //   fetch(scriptURL, { method: "POST", body: requestBody })
  //     .then((response) => {
  //       alert(
  //         "Thankyou for connecting with us , we will contact you soon.",
  //         response
  //       );
  //       submitButton.disabled = false;
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       alert(
  //         "Sorry, some error hass been occured please write us an email on the given email address or try again later",
  //         error.message
  //       );
  //       submitButton.disabled = false;
  //       window.location.reload();
  //     });
  // });

  return (
    <section
      id="contact"
      style={{ backgroundImage: "linear-gradient(black, #070F2B)" }}
    >
      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center">
          <strong>Contact Us - We'll Reach Out to You Soon!</strong>
        </h2>
        <p className="color-blue text-center py-2 mb-3">
          <strong>Fill in the Form Below and Expect Our Prompt Response</strong>
        </p>
        <div className="row my-3 justify-content-center align-items-center ">
          <div className="col-md-6 d-flex justify-content-center">
            <div className="card card-body border-0 bg-blue min-card">
              <form action="" id="form">
                <div className="row">
                  <div className="col-12 py-2">
                    <input
                      type="name"
                      name="name"
                      className="form-control bg-blue-light"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-blue-light"
                      placeholder="Your Email Address"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="number"
                      name="number"
                      className="form-control bg-blue-light"
                      placeholder="Your Mobile Number"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <textarea
                      name="msg"
                      id=""
                      cols={35}
                      className="form-control bg-blue-light"
                      rows={5}
                      placeholder="How Can I help You"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-cneter">
                    <input
                      type="submit"
                      id="submit"
                      className="btn btn-warning w-100"
                      value="SEND"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
