import { Link } from "react-router-dom";

function OurWorkCard({
  title,
  workImg,
  goToLink,
}: {
  title: string;
  workImg: string;
  goToLink: string;
}) {
  return (
    <div className="col-4">
      <Link to={goToLink} target="_blank">
        <img src={workImg} className="w-100 rounded" alt={title} />
      </Link>
    </div>
  );
}

export default OurWorkCard;
