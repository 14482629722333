function DmAboutDigital() {
  return (
    <section id="about-digital">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 pt-2 pb-4">
            <h2 className="text-center text-light">Services We Provide</h2>
            <p className="text-light text-center">
              VyomsCode: Your One-Stop Solution for Comprehensive App
              Development Services.
            </p>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100 bg-digital">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Search Engine Optimization (SEO)
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Enhancing your website's visibility and ranking on search
                  engine results pages to drive organic traffic.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100 bg-digital">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Pay-Per-Click (PPC) Advertising
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Running targeted advertising campaigns on platforms like
                  Google Ads and social media to attract relevant leads and
                  customers.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100 bg-digital">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Social Media Marketing (SMM)
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Engaging with your audience on popular social media platforms,
                  building brand awareness, and driving traffic to your website.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100 bg-digital">
              <div className="card-header">
                <h5 className="text-light text-center">Content Marketing</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Creating valuable and relevant content to attract and retain
                  your target audience, ultimately driving conversions and
                  sales.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100 bg-digital">
              <div className="card-header">
                <h5 className="text-light text-center">Email Marketing</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Nurturing leads and maintaining customer relationships through
                  personalized email campaigns, promoting products, and sharing
                  valuable content.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card h-100 bg-digital">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Analytics and Reporting
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Monitoring key performance indicators (KPIs) and providing
                  detailed reports on the effectiveness of your digital
                  marketing efforts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DmAboutDigital;
