import WorkPageHome from "./work-subcomponents/WorkPageHome";
import WebWork from "./work-subcomponents/WebWork";
import GraphicWork from "./work-subcomponents/GraphicWork";
import AppWork from "./work-subcomponents/AppWork";

function Work() {
  return (
    <div>
      <WorkPageHome />

      <WebWork />

      <GraphicWork />

      <AppWork />
    </div>
  );
}

export default Work;
