import PricingApp from "./pricing-subcomponents/PricingApp";
import PricingDigital from "./pricing-subcomponents/PricingDigital";
import PricingGraphic from "./pricing-subcomponents/PricingGraphic";
import PricingPageHome from "./pricing-subcomponents/PricingPageHome";
import PricingWeb from "./pricing-subcomponents/PricingWeb";

function Pricing() {
  return (
    <div>
      <PricingPageHome />

      <PricingWeb />

      <PricingDigital />

      <PricingGraphic />

      <PricingApp />
    </div>
  );
}

export default Pricing;
