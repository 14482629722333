import AdAboutApp from "./app-development-subcomponents/AdAboutApp";
import AdAppProjects from "./app-development-subcomponents/AdAppProjects";
import AdPageHome from "./app-development-subcomponents/AdPageHome";

function AppDevelopment() {
  return (
    <div>
      <AdPageHome />

      <AdAboutApp />

      <AdAppProjects />
    </div>
  );
}

export default AppDevelopment;
