import { Link } from "react-router-dom";

function WebWorkCard({
  webTitle,
  webImage,
  webDescription,
  webLink,
}: {
  webTitle: string;
  webImage: string;
  webDescription: string;
  webLink: string;
}) {
  return (
    <div className="col-md-3 my-1">
      <div className="card h-100 bg-blue">
        <div className="card-head">
          <img
            src={webImage}
            className="card-img-top rounded-top"
            alt={webTitle}
          />
        </div>
        <div className="card-body">
          <h5 className="text-light text-center">{webTitle}</h5>
          <p className="text-light text-center">{webDescription}</p>
        </div>
        <div className="card-footer">
          <h5 className="text-center">
            <Link to={webLink} target="_blank">
              <button className="btn btn-warning">View Project</button>
            </Link>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default WebWorkCard;
