function ShowCaseLargeDevice({ Img, Alt }: { Img: string; Alt: string }) {
  return (
    <div className="col-md-3 my-1">
      <div className="">
        <img src={Img} alt={Alt} className="w-100" />
      </div>
    </div>
  );
}

export default ShowCaseLargeDevice;
