import { Link } from "react-router-dom";

function PricingDigital() {
  return (
    <section id="pricing-digital" className="pricing">
      <div className="container pb-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h3 className="text-center text-light">
              <b>Digital Marketing</b>
            </h3>
            <p className="text-center text-light">
              Contact us to know about pricing plan which include React Js,
              Angular Js, Laravel frameworks.
            </p>
            <h6 className="text-center">
              <Link to="/services/digital-marketing" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>
          <div className="card card-body">
            <div className="row">
              <div className="col-md-3 border py-2 my-1">
                <h5 className="text-light">SEO KICKSTART</h5>
                <h5 className="text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 16550/month
                </h5>
                <p className="text-light">
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 25
                  SEO Keywords
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 50
                  Quality Backlinks
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 5
                  Landing Pages
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i>Google My
                  Business (GMB)
                  <br />
                  <br />
                  <b>First Hand SEO Analysis</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Pre-Optimization Website Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Competitor Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Keyword Research & Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Baseline Ranking Check
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Duplicate
                  Content Check
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Google
                  Penalty Check
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Back Link
                  Analysis (If required)
                  <br />
                  <br />
                  <b> On-Page Optimization Activities</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Canonical Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Title
                  Tag Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> META
                  Tags Optimization
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Heading
                  Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Image
                  Alt Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Content Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> SEO
                  Friendly URL Setup
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Site
                  Navigation Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 404
                  Page Implementation
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Broken
                  Links Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Speed Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Indexed Pages Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Robots txt Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google XML Sitemap
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> HTML
                  Sitemap Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Webmasters Tool Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Analytics Setup
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Structured
                  Data Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  On-Site Blog Section Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1
                  On-Site Blog Posting
                  <br />
                  <br />
                  <b> Off-Page Optimization Activities </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Search Engine Submission-Manual
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1
                  Article Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1
                  Article Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 5
                  Article Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  Classified Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Business Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1
                  Blog Creation (One-Time)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1
                  Blog Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1
                  Blog Posting
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 5
                  Blog Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 5
                  Third Party Blog Pinging
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  Image sharing
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> PPT
                  Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 10
                  Social Bookmarking
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Profile Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Product Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Video
                  Marketing (if Client provides)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Press
                  Release (If Client Provides News)
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> OSB
                  Marketing <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Quora Q&A
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i>
                  Infographic Submission
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Guest Post
                  <br />
                  <br />
                  <b> Social Media Optimization (SMO) Activities </b>
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Facebook
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Instagram
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Twitter
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Linkedin
                  <br />
                  <br />
                  <b> Reports </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Website Analytics Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Keywords Ranking Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Off Page Submission Report
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Bi-Monthly
                  Website Progress Report
                  <br />
                  <br />
                  <b> Customer Support </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Email
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Phone
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Chat
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i>
                  Google/Zoom Meeting
                  <br />
                </p>
                <h6>
                  <Link to="https://wa.me/+917042562687" target="_blank">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
              <div className="col-md-3 border py-2 my-1">
                <h5 className="text-light">SEO Essential</h5>
                <h5 className="text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 28999/month
                </h5>
                <p className="text-light">
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 50
                  SEO Keywords
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 100
                  Quality Backlinks
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 10
                  Landing Pages
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i>Google My
                  Business (GMB)
                  <br />
                  <br />
                  <b>First Hand SEO Analysis</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Pre-Optimization Website Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Competitor Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Keyword Research & Analysin
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Baseline Ranking Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Duplicate Content Check
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Google
                  Penalty Check
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Back Link
                  Analysis (If required)
                  <br />
                  <br />
                  <b> On-Page Optimization Activities</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Canonical Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Title
                  Tag Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> META
                  Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Heading Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Image
                  Alt Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Content Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> SEO
                  Friendly URL Setup
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Site
                  Navigation Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 404
                  Page Implementation
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Broken
                  Links Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Speed Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Indexed Pages Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Robots txt Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google XML Sitemap
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> HTML
                  Sitemap Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Webmasters Tool Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Analytics Setup
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Structured
                  Data Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  On-Site Blog Section Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  On-Site Blog Posting
                  <br />
                  <br />
                  <b> Off-Page Optimization Activities </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Search Engine Submission Manual
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  Article Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  Article Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 10
                  Article Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 5
                  Classified Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Business Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  Blog Creation (One-Time)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  Blog Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 2
                  Blog Posting
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 10
                  Blog Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 5
                  Third Party Blog Pinging
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 4
                  Image sharing
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> PPT
                  Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 15
                  Social Bookmarking
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Profile Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Product Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Video
                  Marketing (if Client provides)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Press
                  Release (If Client Provides News)
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> OSB
                  Marketing <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Quora Q&A
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i>
                  Infographic Submission
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Guest Post
                  <br />
                  <br />
                  <b> Social Media Optimization (SMO) Activities </b>
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Facebook
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Instagram
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Twitter
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Linkedin
                  <br />
                  <br />
                  <b> Reports </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Website Analytics Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Keywords Ranking Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Off Page Submission Report
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Bi-Monthly
                  Website Progress Report
                  <br />
                  <br />
                  <b> Customer Support </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Email
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Phone
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Chat
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i>
                  Google/Zoom Meeting
                  <br />
                </p>
                <h6>
                  <Link to="https://wa.me/+917042562687" target="_blank">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
              <div className="col-md-3 border py-2 my-1">
                <h5 className="text-light">SEO Advanced</h5>
                <h5 className="text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 40999/month
                </h5>
                <p className="text-light">
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 75
                  SEO Keywords
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 150
                  Quality Backlinks
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 15
                  Landing Pages
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google My Business (GMB)
                  <br />
                  <br />
                  <b>First Hand SEO Analysis</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Pre-Optimization Website Analysis <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Competitor Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Keyword Research & Analysin
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Baseline Ranking Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Duplicate Content Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Penalty Check
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Back Link
                  Analysis (If required)
                  <br />
                  <br />
                  <b> On-Page Optimization Activities</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Canonical Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Title
                  Tag Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> META
                  Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Heading Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Image
                  Alt Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Content Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> SEO
                  Friendly URL Setup
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Site
                  Navigation Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 404
                  Page Implementation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Broken Links Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Speed Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Indexed Pages Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Robots txt Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google XML Sitemap
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> HTML
                  Sitemap Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Webmasters Tool Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Analytics Setup
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Structured
                  Data Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  On-Site Blog Section Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  On-Site Blog Posting
                  <br />
                  <br />
                  <b> Off-Page Optimization Activities </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Search Engine Submission Manual
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  Article Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  Article Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 15
                  Article Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 10
                  Classified Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Business Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  Blog Creation (One-Time)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  Blog Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  Blog Posting
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 15
                  Blog Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 10
                  Third Party Blog Pinging
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 6
                  Image sharing
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> PPT
                  Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 20
                  Social Bookmarking
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Profile Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Product Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Video
                  Marketing (if Client provides)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Press
                  Release (If Client Provides News)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> OSB
                  Marketing <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Quora
                  Q&A
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Infographic Submission
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Guest
                  Post
                  <br />
                  <br />
                  <b> Social Media Optimization (SMO) Activities </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Facebook 8 Posts/month
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Instagram 8 Posts/month
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Twitter 8 Posts/month
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Linkedin 8 Posts/month
                  <br />
                  <br />
                  <b> Reports </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Website Analytics Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Keywords Ranking Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Off Page Submission Report
                  <br />
                  <i className="bi bi-x-circle-fill text-danger"></i> Bi-Monthly
                  Website Progress Report
                  <br />
                  <br />
                  <b> Customer Support </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Email
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Phone
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Chat
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google/Zoom Meeting
                  <br />
                </p>
                <h6>
                  <Link to="https://wa.me/+917042562687" target="_blank">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
              <div className="col-md-3 border py-2 my-1">
                <h5 className="text-light">SEO Professional</h5>
                <h5 className="text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 60999/month
                </h5>
                <p className="text-light">
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 100
                  SEO Keywords
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 200
                  Quality Backlinks
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 30
                  Landing Pages
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google My Business (GMB)
                  <br />
                  <br />
                  <b>First Hand SEO Analysis</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Pre-Optimization Website Analysis <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Competitor Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Keyword Research & Analysin
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Baseline Ranking Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Duplicate Content Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Penalty Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Back
                  Link Analysis (If required)
                  <br />
                  <br />
                  <b> On-Page Optimization Activities</b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Canonical Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Title
                  Tag Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> META
                  Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Heading Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Image
                  Alt Tags Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Content Optimization
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> SEO
                  Friendly URL Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Site
                  Navigation Analysis
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 404
                  Page Implementation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Broken Links Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Website Speed Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Indexed Pages Check
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Robots txt Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google XML Sitemap
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> HTML
                  Sitemap Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Webmasters Tool Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google Analytics Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Structured Data Setup
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  On-Site Blog Section Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 3
                  On-Site Blog Posting
                  <br />
                  <br />
                  <b> Off-Page Optimization Activities </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Search Engine Submission Manual
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 4
                  Article Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 4
                  Article Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 20
                  Article Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 15
                  Classified Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Business Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 4
                  Blog Creation (One-Time)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 4
                  Blog Writing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 4
                  Blog Posting
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 20
                  Blog Marketing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 15
                  Third Party Blog Pinging
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 8
                  Image sharing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 1 PPT
                  Submissions
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> 30
                  Social Bookmarking
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Profile Creation
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Product Listing
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Video
                  Marketing (if Client provides)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Press
                  Release (If Client Provides News)
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> OSB
                  Marketing <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Quora
                  Q&A
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Infographic Submission
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Guest
                  Post
                  <br />
                  <br />
                  <b> Social Media Optimization (SMO) Activities </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Facebook 16 Posts/month
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Instagram 16 Posts/month
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Twitter 16 Posts/month
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Linkedin 16 Posts/month
                  <br />
                  <br />
                  <b> Reports </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Website Analytics Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Keywords Ranking Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Monthly Off Page Submission Report
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Bi-Monthly Website Progress Report
                  <br />
                  <br />
                  <b> Customer Support </b>
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Email
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Phone
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i> Chat
                  <br />
                  <i className="bi bi-check-circle-fill text-success"></i>
                  Google/Zoom Meeting
                  <br />
                </p>
                <h6>
                  <Link to="https://wa.me/+917042562687" target="_blank">
                    <button className="btn btn-warning btn-sm">
                      Conatct Us Now
                    </button>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingDigital;
