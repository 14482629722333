import WdAboutWeb from "./website-development-subcomponents/WdAboutWeb";
import WdWebHome from "./website-development-subcomponents/WdWebHome";
import WdWebProjects from "./website-development-subcomponents/WdWebProjects";

function WebsiteDevelopment() {
  return (
    <div>
      <WdWebHome />

      <WdAboutWeb />

      <WdWebProjects />
    </div>
  );
}

export default WebsiteDevelopment;
