import DigitalImg from "../../../../assets/digital.webp";

function DmPageHome() {
  return (
    <section id="digital-home" className="pb-md-5">
      <div className="container py-5 ">
        <div className="row align-items-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>
                We Grow Your Revenue With <br className="break" />
                Results-driven SEO
              </b>
            </h3>
            <p className="text-light text-center">
              Drive Growth and Engagement with Strategic Digital Marketing
              Strategies.
            </p>
            <div className="d-flex justify-content-center">
              <a className="nav-link" href="#about-digital">
                <button className="btn btn-outline-light  mx-1">
                  Digital Marketing Services
                </button>
              </a>
              {/* <a className="nav-link" href="#app-projects"><button className="btn btn-outline-light  mx-1">App Projects</button></a> */}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center mt-3">
            <img
              src={DigitalImg}
              className=" rounded digital-img"
              alt="Digital Marketing"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DmPageHome;
