import { Link } from "react-router-dom";

function ServicesSmallDevice({
  id,
  title,
  img,
  goToLink,
}: {
  id: number;
  title: string;
  img: string;
  goToLink: string;
}) {
  return (
    <div className={id === 1 ? "carousel-item active" : "carousel-item"}>
      <div className="card service-card">
        <div className="card-header">
          <h5 className="text-center">{title}</h5>
        </div>
        <div className="card-body d-flex justify-content-center">
          <img src={img} className="w-100" alt={title} />
        </div>
        <div className="card-footer">
          {/* <p className="text-light text-center">
                                        Crafting responsive websites tailored to your needs, ensuring seamless functionality across all devices. Our user-friendly interfaces and intuitive navigation make browsing a breeze, while our SEO optimization ensures maximum visibility in search results. With fast loading speeds and scalable solutions, your website will grow with your business effortlessly.
                                    </p> */}
          <h6 className="text-center">
            <Link to={goToLink} className="">
              <button className="btn btn-warning">Explore</button>
            </Link>
          </h6>
        </div>
      </div>
    </div>
  );
}

export default ServicesSmallDevice;
