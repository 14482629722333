function GraphicDesignUiUxCard({
  graphicTitle,
  graphicImage,
  graphicImageAlt,
}: {
  graphicTitle: string;
  graphicImage: string;
  graphicImageAlt: string;
}) {
  return (
    <div className="col-md-4 my-1">
      <div className="card h-100  bg-blue">
        <div className="card-head">
          <img
            src={graphicImage}
            className="card-img-top rounded-top"
            alt={graphicImageAlt}
          />
        </div>
        <div className="card-body">
          <p className="text-center mb-0 text-light">{graphicTitle}</p>
        </div>
      </div>
    </div>
  );
}

export default GraphicDesignUiUxCard;
