import { Link } from "react-router-dom";

function AboutUsProfileLargeDevice({
  name,
  profileImg,
  whatsappLink,
  linkedinLink,
  instagramLink,
  portfolioLink,
  aboutMeText,
}: {
  name: string;
  profileImg: string;
  whatsappLink: string;
  linkedinLink: string;
  instagramLink: string;
  portfolioLink: string;
  aboutMeText: string;
}) {
  return (
    <div className="col-md-3">
      <div className="card ">
        <div className="front">
          <div className="card-header">
            <p className="text-center mb-0 text-small large-hide">
              Click to know more
            </p>
            <img src={profileImg} className="w-100" alt={name} />
          </div>
          <div className="card-body">
            <h4 className="text-start text-head blue-text">
              <b>{name}</b>
            </h4>
            <div className="d-flex justify-content-end align-items-center">
              <Link
                to={whatsappLink}
                target="_blank"
                className="nav-link text-success fs-5"
              >
                <i className="bi bi-whatsapp mx-1"></i>
              </Link>
              <Link
                to={linkedinLink}
                target="_blank"
                className="nav-link text-primary fs-5"
              >
                <i className="bi bi-linkedin mx-1"></i>
              </Link>
              <Link
                to={instagramLink}
                className="nav-link text-danger fs-5"
                target="_blank"
              >
                <i className="bi bi-instagram mx-1"></i>
              </Link>
              <Link
                to={portfolioLink}
                target="_blank"
                className=" btn btn-outline-dark py-1 px-2 mx-2 fs-6"
              >
                Portfolio
              </Link>
            </div>
          </div>
        </div>
        <div className="back">
          <div
            className="card-body"
            style={{ overflowY: "scroll", height: "85%" }}
          >
            <h4 className="text-start text-head blue-text">
              <b>Rudra Pratap Singh</b>
            </h4>
            <div className="text-head">{aboutMeText}</div>
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-end align-items-center">
              <Link
                target="_blank"
                to={whatsappLink}
                className="nav-link text-success fs-5"
              >
                <i className="bi bi-whatsapp mx-1"></i>
              </Link>
              <Link
                target="_blank"
                to={linkedinLink}
                className="nav-link text-primary fs-5"
              >
                <i className="bi bi-linkedin mx-1"></i>
              </Link>
              <Link
                target="_blank"
                to={instagramLink}
                className="nav-link text-danger fs-5"
              >
                <i className="bi bi-instagram mx-1"></i>
              </Link>
              <Link
                target="_blank"
                to={portfolioLink}
                className=" btn btn-outline-dark py-1 px-2 mx-2 fs-6"
              >
                Portfolio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsProfileLargeDevice;
