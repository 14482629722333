function PricingPageHome() {
  return (
    <section id="pricing-home">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Our Pricing Plan</b>
            </h3>
            <p className="text-light text-center">
              Flexible Pricing Structure: Rates Subject to Project Size and
              Completion Time
            </p>
            <div className="d-flex text-center justify-content-center">
              <a className="nav-link" href="#pricing-graphic">
                <button className="btn btn-outline-light  m-1">
                  Graphic Design
                </button>
              </a>
              <a className="nav-link" href="#pricing-app">
                <button className="btn btn-outline-light  m-1">
                  App Devlopment
                </button>
              </a>
            </div>
            <div className="d-flex justify-content-center  my-md-0">
              <a className="nav-link" href="#pricing-web">
                <button className="btn btn-outline-light  m-1">
                  Website Devlopment
                </button>
              </a>
              <a className="nav-link" href="#pricing-digital">
                <button className="btn btn-outline-light  m-1">
                  Digital Marketing
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingPageHome;
