import axios, { AxiosError } from "axios";

export async function BlogDataApi() {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_BLOG_DATA_API,
  };

  const result = axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      return JSON.stringify(response.data);
    })
    .catch((error: AxiosError) => {
      console.log(error);
      return JSON.stringify([]);
    });

  return result;
}
