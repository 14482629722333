// import Graphic1 from "../../../../assets/graphic-1.svg";
// import Graphic2 from "../../../../assets/graphic-2.svg";

function GdPageHome() {
  return (
    <section id="graphic-home" className="">
      <div className="container-fluid px-0 ">
        <div className="row align-items-center mx-0">
          <div className="col-md-3 small-hide px-0">
            <img
              // src={Graphic1}
              className="w-100"
              alt="graphic design"
            />
          </div>
          <div className="col-md-6 py-5">
            <h3 className="text-center heading-graphic">
              <b>
                Elevate Your Brand with <br className="break" /> Creative
                Graphic Design Solutions
              </b>
            </h3>
            <p className=" text-center">
              Transform Your Vision into Stunning Visuals with VyomsCode's
              Graphic Design Expertise
            </p>
            <div className="d-flex justify-content-center">
              <a className="nav-link" href="#about-graphic">
                <button className="btn btn-graphic  mx-1">
                  Graphic Design Services
                </button>
              </a>
              {/* <a className="nav-link" href="#app-projects"><button className="btn btn-outline-light  mx-1">App Projects</button></a> */}
            </div>
          </div>
          <div className="col-md-3 px-0">
            <img
              // src={Graphic2}
              className="w-100"
              alt="graphic design"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default GdPageHome;
