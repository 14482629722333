function WdAboutWeb() {
  return (
    <section id="about-web">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 pt-2 pb-4">
            <h2 className="text-center text-light">Services We Provide</h2>
            <p className="text-light text-center">
              We offer comprehensive web development services tailored to your
              needs and facilitate hosting and domain setup with third-party
              providers
            </p>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Custom Website Development
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Crafting unique, responsive websites that reflect your brand
                  identity and cater to your target audience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">E-commerce Solutions</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Building robust online stores with secure payment gateways and
                  intuitive user interfaces to drive sales.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">CMS Development</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Creating user-friendly content management systems for easy
                  website updates and management.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Web Application Development
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Developing scalable web applications with advanced
                  functionalities to meet your business requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">UI/UX Design</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Designing visually appealing and intuitive interfaces to
                  enhance user engagement and satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Website Maintenance and Support
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Providing ongoing maintenance and support services to ensure
                  your website runs smoothly and stays up-to-date with the
                  latest technologies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">Hosting Setup</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Assisting in selecting the right hosting provider and
                  configuring servers to ensure optimal website performance and
                  reliability.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">Domain Registration</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Helping you register a memorable domain name that aligns with
                  your brand and securing it for your online presence.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">DNS Configuration</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Managing domain name system (DNS) settings to point your
                  domain to the correct hosting server for seamless website
                  access.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">Email Configuration</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Setting up professional email addresses associated with your
                  domain for effective communication with clients and customers.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">
                  SSL Certificate Installation
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Installing SSL certificates to encrypt data transmitted
                  between your website and visitors' browsers, ensuring secure
                  transactions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-blue">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Continuous Monitoring
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Monitoring hosting and domain services to detect and resolve
                  any issues promptly, ensuring uninterrupted website
                  availability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WdAboutWeb;
