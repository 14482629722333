function GdAboutGraphic() {
  return (
    <section id="about-graphic">
      <div className="container py-5">
        <div className="row py-md-5">
          <div className="col-12 pt-2 pb-4">
            <h2 className="text-center  heading-graphic">
              Services We Provide
            </h2>
            <p className=" text-center">
              <b>
                Elevate Your Brand's Visual Identity with VyomsCode's Graphic
                Design Expertise
              </b>
            </p>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-yellow">
              <div className="card-head">
                <h5 className="text-light text-center">Logo Design</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Crafting unique and memorable logos that embody your brand
                  identity.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-yellow">
              <div className="card-head">
                <h5 className="text-light text-center">Print Design</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Designing print materials such as business cards, brochures,
                  flyers, and posters for effective offline marketing.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-yellow">
              <div className="card-head">
                <h5 className="text-light text-center">Digital Design</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Creating engaging digital assets such as social media
                  graphics, banners, and email templates.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-yellow">
              <div className="card-head">
                <h5 className="text-light text-center">UI/UX Design</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Designing intuitive and visually appealing user interfaces for
                  websites and mobile applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GdAboutGraphic;
