import { Link } from "react-router-dom";

function PricingGraphic() {
  return (
    <section id="pricing-graphic" className="pricing">
      <div className="container  pb-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h3 className="text-center text-light">
              <b>Graphic Design</b>
            </h3>
            <p className="text-center text-light ">
              Price May Vary According to Completion Time{" "}
            </p>
            <h6 className="text-center">
              <Link to="/services/graphic-designing" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-3 my-2">
            <div className="card h-100">
              <div className="card-header">
                <h5 className="text-center text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 999/
                </h5>
              </div>
              <div className="card-body">
                <p className="text-center text-light">Logo Design</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-2">
            <div className="card h-100">
              <div className="card-header">
                <h5 className="text-center text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 699/
                </h5>
              </div>
              <div className="card-body">
                <p className="text-center text-light">Poster Design</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-2">
            <div className="card h-100">
              <div className="card-header">
                <h5 className="text-center text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 899/
                </h5>
              </div>
              <div className="card-body">
                <p className="text-center text-light">
                  Banner and Flex Board Design
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-3 my-2">
            <div className="card h-100">
              <div className="card-header">
                <h5 className="text-center text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 1499/
                </h5>
              </div>
              <div className="card-body">
                <p className="text-center text-light">
                  UI/UX Design for App and Websites
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-2">
            <div className="card h-100">
              <div className="card-header">
                <h5 className="text-center text-light mb-0">
                  <span className="text-small">Starting at</span> ₹ 499/
                </h5>
              </div>
              <div className="card-body">
                <p className="text-center text-light">
                  Social Media Post Design
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingGraphic;
