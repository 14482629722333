import { Link } from "react-router-dom";

function WorkPageHome() {
  return (
    <section id="work-home">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Innovative Projects Showcase </b>
            </h3>
            <p className="text-light text-center">
              Exploring the Frontiers of Web Development, App Development, and
              Graphic Design
            </p>
            <div className="d-md-flex text-center justify-content-center">
              <a className="nav-link" href="#graphic">
                <button className="btn btn-outline-light  mx-1">Graphic</button>
              </a>
              <br className="large-hide" />
              <div className="d-flex justify-content-center  my-md-0">
                <a className="nav-link" href="#web">
                  <button className="btn btn-outline-light  mx-1">
                    Website Project
                  </button>
                </a>
                <a className="nav-link" href="#app">
                  <button className="btn btn-outline-light  mx-1">
                    App Project
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2 ">
            <p className="text-center text-light">
              Our web design services cover diverse categories, including many
              types of websites, and we are open to creating websites for other
              unique ideas. We believe in the power of customization and
              tailoring websites to suit your specific needs. Your satisfaction
              is our priority, and we're committed to delivering a visually
              appealing, user-friendly, and engaging website that aligns
              perfectly with your goals. Contact us to discuss your project and
              bring your vision to life.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkPageHome;
