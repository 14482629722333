import TestimonialCarousel from "../reusable-components/TestimonialCarousel";

type TestimonialDataType = {
  id: number;
  personName: string;
  personCompany: string;
  personTitle: string;
  testimonialText: string;
};

function MpTestimonials() {
  const testimonialData: TestimonialDataType[] = [
    {
      id: 1,
      personName: "Brijesh Vaishnav",
      personCompany: "Techonica",
      personTitle: "CEO",
      testimonialText:
        "Working with VyomsCode Website Service has been an absolutely awesome experience! Their dedication and expertise have left a lasting impression on me. I am extremely satisfied with the quality of their work and the deliverables, which exceeded our expectations. Without a doubt, I highly recommend VyomsCode Website Service to others. From start to finish, they remained communicative, attentive, and receptive to our needs, making the entire experience smooth and enjoyable. VyomsCode Website Service is a provider you can trust to deliver exceptional results. I am grateful for the opportunity to work with them, and I look forward to future projects together.",
    },
    {
      id: 2,
      personName: "Puneet",
      personCompany: "BookMeriGadi",
      personTitle: "---",
      testimonialText: "Yet to come ...",
    },
  ];
  return (
    <section id="testimonials">
      <div className="container p-md-5 text-light py-5">
        <h2 className="color-blue text-center my-3">
          <strong>Our Testimonials</strong>
        </h2>
        <div id="carouselExample" className="carousel slide">
          <div className="carousel-inner">
            {testimonialData.map((item) => {
              return (
                <TestimonialCarousel
                  key={item.id}
                  id={item.id}
                  testimonialText={item.testimonialText}
                  personName={item.personName}
                  personCompany={item.personCompany}
                  personTitle={item.personTitle}
                />
              );
            })}
            {/* <div className="carousel-item ">
                    <img src="assets/crousel2.jpg" className="d-block w-100"  alt="" />
                    </div>
                    <div className="carousel-item">
                    <img src="assets/crousel3.jpg" className="d-block w-100" alt="..." />
                    </div> */}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default MpTestimonials;
