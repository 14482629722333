import { Route, Routes } from "react-router-dom";
import MainPage from "./main-page/MainPage";
import PageNotFound from "./common-components/PageNotFound";
import ContactUs from "./other-pages/ContactUs";
import TermsAndConditions from "./other-pages/TermsAndConditions";
import PrivacyPolicy from "./other-pages/PrivacyPolicy";
import ServicesHome from "./Services/ServicesHome";
import Header from "./common-components/Header";
import ContactFooter from "./common-components/ContactFooter";
import Footer from "./common-components/Footer";
import Pricing from "./other-pages/Pricing";

import Work from "./other-pages/Work";
import GoToTopButton from "./common-components/GoToTopButton";

function DefaultHome() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/services/*" element={<ServicesHome />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <ContactFooter />
      <Footer />
      <GoToTopButton />
    </div>
  );
}

export default DefaultHome;
