// web work
import TextUtil from "../assets/project-img/text-util.webp";
import Charity from "../assets/project-img/charity.webp";
import FlipCard from "../assets/project-img/flip-card.webp";
import Hotel from "../assets/project-img/Hotel.webp";
import OnlineEd from "../assets/project-img/online-ed.webp";
import Photography from "../assets/project-img/photography.webp";
import Blog1 from "../assets/project-img/blog1.webp";
import Dashboard1 from "../assets/project-img/dashboard1.webp";
import Dashboard2 from "../assets/project-img/dashboard2.webp";
import Dashboard3 from "../assets/project-img/dashboard3.webp";
import Solar from "../assets/project-img/solor.webp";

// graphic designing work
import GraphicProjectImg from "../assets/project-img/graphic-project.webp";
import ZeroImg from "../assets/project-img/zero.webp";
import WomensDay1Img from "../assets/project-img/womensday1.webp";
import WomensDay2Img from "../assets/project-img/womensday2.webp";
import MahaShivratri1Img from "../assets/project-img/mahashivratri1.webp";
import MahaShivratri2Img from "../assets/project-img/mahashivratri2.webp";
import LudoGame1Img from "../assets/project-img/ludogame1.svg";
import LudoGame2Img from "../assets/project-img/ludogame2.svg";
import ColorGame1Img from "../assets/project-img/colorgame1.svg";
import ColorGame2Img from "../assets/project-img/colorgame2.svg";
import School2Img from "../assets/project-img/school2.svg";
import School1Img from "../assets/project-img/school1.svg";

// main page
import WebImg from "../assets/web.webp";
import AppVyomsImg from "../assets/app-vyoms.webp";
import GraphicVyomsImg from "../assets/graphic-vyoms.webp";
import MarketImg from "../assets/market.webp";

import RudraProfileImg from "../assets/profile-rudra.webp";
import SatyaProfileImg from "../assets/satya-profile.jpg";
import TriptiProfileImg from "../assets/profile-tripti.webp";

import WebDevImg from "../assets/web-dev.webp";
import GraphicDesignImg from "../assets/graphic.webp";
import AppDevImg from "../assets/app-dev.webp";
import DigitalMarketingImg from "../assets/digital-market.webp";

import Dashboard1Img from "../assets/project-img/dashboard1.webp";
import Dashboard2Img from "../assets/project-img/dashboard2.webp";
import Dashboard3Img from "../assets/project-img/dashboard3.webp";

// data types
import {
  GraphicDesignDataType,
  ImgListType,
  OurWorkType,
  profileType,
  servicesDataType,
  WebWorkDataType,
} from "./dataType";

export const webWorkData: WebWorkDataType[] = [
  {
    id: 1,
    webTitle: "Text-Util Websites",
    webImage: TextUtil,
    webDescription:
      "This project is built using React.In this project, we can count words, characters, and manipulate paragraphs into lowercase or uppercase.",
    webLink: "https://tripti.vyomscode.com/webdev/text-util/",
  },
  {
    id: 2,
    webTitle: "Nonprofit Websites",
    webImage: Charity,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap . This project is an informational website for Charitable Organizations.",
    webLink: "https://vyomscode.com/webdev/charitable/index.html",
  },
  {
    id: 3,
    webTitle: "Flip Card Portfolio Websites",
    webImage: FlipCard,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap . This project is a portfolio website with unique card flip effect.",
    webLink: "https://vyomscode.com/webdev/flip_card/index.html",
  },
  {
    id: 4,
    webTitle: "Business website",
    webImage: Hotel,
    webDescription:
      "Frontend project using HTML, CSS, JS. This project is an informational website for Hotel.",
    webLink: "https://vyomscode.com/webdev/business/index.html",
  },
  {
    id: 5,
    webTitle: "Educational Websites",
    webImage: OnlineEd,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap . This project is an informational website for Online Learning Platforms.",
    webLink: "https://vyomscode.com/webdev/acedmic/index.html",
  },
  {
    id: 6,
    webTitle: "Portfolio Websites",
    webImage: Photography,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap . This project is an Portfolio website for Photography Portfolio.",
    webLink: "https://vyomscode.com/webdev/photography/index.html",
  },
  {
    id: 7,
    webTitle: "Blogging websites",
    webImage: Blog1,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap . This project is a Blogging website for Food Blogs.",
    webLink: "https://vyomscode.com/webdev/blog/index.html",
  },
  {
    id: 8,
    webTitle: "Websites Admin Dashboards",
    webImage: Dashboard1,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap, JQuery . This project is a Dashboard website That can be used for any Business Admins.",
    webLink: "https://vyomscode.com/webdev/admin-dashboard/index.php",
  },
  {
    id: 9,
    webTitle: "Websites User Dashboards",
    webImage: Dashboard2,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap, JQuery . This project is a Dashboard website That can be used for any Business Users.",
    webLink: "https://vyomscode.com/webdev/user-dashboard/index.php",
  },
  {
    id: 10,
    webTitle: "Websites Student Dashboards",
    webImage: Dashboard3,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap, JQuery . This project is a Dashboard website that can be used for Student Work.",
    webLink: "https://vyomscode.com/webdev/student-dashboard/index.html",
  },
  {
    id: 11,
    webTitle: "Solar System Informational Website",
    webImage: Solar,
    webDescription:
      "Frontend project using HTML, CSS, JS, Bootstrap, JQuery . This project is a Solar System Informational Website with cool scrolling effect.",
    webLink: "https://vyomscode.com/webdev/planetview/index.html",
  },
];

export const GraphicDesignData: GraphicDesignDataType[] = [
  {
    id: 1,
    graphicTitle: "Social Media Post related to graphic design",
    graphicImage: GraphicProjectImg,
    graphicImageAlt: "graphic design",
  },
  {
    id: 2,
    graphicTitle: "Social Media Post for Zero Discrimination Day",
    graphicImage: ZeroImg,
    graphicImageAlt: "Zero Discrimination Day",
  },
  {
    id: 3,
    graphicTitle: "Social Media Post on International Women's Day",
    graphicImage: WomensDay1Img,
    graphicImageAlt: "Women's Day",
  },
  {
    id: 4,
    graphicTitle: "Social Media Post on International Women's Day",
    graphicImage: WomensDay2Img,
    graphicImageAlt: "Women's Day",
  },
  {
    id: 5,
    graphicTitle: "Social Media Post on Maha Shivratri",
    graphicImage: MahaShivratri1Img,
    graphicImageAlt: "Maha Shivratri",
  },
  {
    id: 6,
    graphicTitle: "Social Media Post on Maha Shivratri",
    graphicImage: MahaShivratri2Img,
    graphicImageAlt: "Maha Shivratri",
  },
];

export const GraphicUIUXData: GraphicDesignDataType[] = [
  {
    id: 1,
    graphicTitle: "UI/UX design of Ludo Game",
    graphicImage: LudoGame1Img,
    graphicImageAlt: "UI/UX design of Ludo Game",
  },
  {
    id: 2,
    graphicTitle: "UI/UX design of Ludo Game",
    graphicImage: LudoGame2Img,
    graphicImageAlt: "UI/UX design of Ludo Game",
  },
  {
    id: 3,
    graphicTitle: "UI/UX design of Color prediction Game",
    graphicImage: ColorGame1Img,
    graphicImageAlt: "UI/UX design of Color prediction Game",
  },
  {
    id: 4,
    graphicTitle: "UI/UX design of Color prediction Game",
    graphicImage: ColorGame2Img,
    graphicImageAlt: "UI/UX design of Color prediction Game",
  },
  {
    id: 5,
    graphicTitle: "UI/UX design of Institute informational website",
    graphicImage: School2Img,
    graphicImageAlt: "UI/UX design of Institute informational website",
  },
  {
    id: 6,
    graphicTitle: "UI/UX design of Institute informational website",
    graphicImage: School1Img,
    graphicImageAlt: "UI/UX design of Institute informational website",
  },
];

// main page

export const ImgList: ImgListType[] = [
  { id: 1, img: WebImg, alt: "Website Devlopment" },
  { id: 2, img: AppVyomsImg, alt: "Aplication Devlopment" },
  { id: 3, img: GraphicVyomsImg, alt: "Graphic Design" },
  { id: 4, img: MarketImg, alt: "Digital Marketing" },
];

export const profileData: profileType[] = [
  {
    id: 1,
    name: "Rudra Pratap Singh",
    profileImg: RudraProfileImg,
    whatsappLink: "https://wa.me/+919118475847",
    linkedinLink:
      "https://www.linkedin.com/in/rudra-pratap-singh-3b3b9a26a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    instagramLink: "https://www.instagram.com/_rudra36_?igsh=MXlicmRlNjdwNnE1",
    portfolioLink: "https://rudra.vyomscode.com/",
    aboutMeText: `🚀 Greetings! I'm Rudra, your go-to PHP MySQL maestro, weaving digital wonders with HTML, CSS, and the symphony of JavaScript. In the realm of mobile app magic, I dance with Java, crafting seamless experiences. 🎓 Armed with a robust education, I bring a fusion of technical finesse and artistic ingenuity to every project. My multilingual prowess in English, Hindi, and Bhojpuri ensures our communication is not just effective but delightful. 💡 Let's transform concepts into digital masterpieces! I thrive on challenges, channeling my passion into ingenious problem-solving. Join me in sculpting a future where innovation knows no bounds!`,
  },
  {
    id: 2,
    name: "Satyajeet Patra",
    profileImg: SatyaProfileImg,
    whatsappLink: "https://wa.me/+919560718335",
    linkedinLink: "www.linkedin.com/in/satyajeet-patra",
    instagramLink: "https://www.instagram.com/satyajeetpatra2001",
    portfolioLink: "https://satyajeet.vyomscode.com/",
    aboutMeText: `Hello! 😊 I'm Satyajeet Patra, a graduate with a Bachelors degree in Computer Science and Engineering. My expertise lies in web development, where I'm well-versed in programming languages such as HTML, CSS, Bootstrap, PHP, JavaScript, SQL, MySQL, Angular, React, and more. 💻 I have an interest in creating captivating user interfaces by combining front-end design and back-end functionality. 🎨💡 Being someone who enjoys solving problems and thinking creatively, I always look forward to taking on challenges and finding ways to innovate. 🚀`,
  },
  {
    id: 3,
    name: "Tripti Thakur",
    profileImg: TriptiProfileImg,
    whatsappLink: "https://wa.me/+917042562687",
    linkedinLink:
      "https://www.linkedin.com/in/tripti-thakur-256431219?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    instagramLink:
      "https://www.instagram.com/triptithakur0?igsh=amd2dTZ0b3Ryb3o3",
    portfolioLink: "https://tripti.vyomscode.com/",
    aboutMeText: `Hey there! 👋 I'm Tripti, a Computer Science BTech graduate with a passion for web development, graphic design, UI/UX design, and social media post creation. 🖥️🎨 I thrive on challenges, love engaging with diverse perspectives, and am always eager to learn something new. 💡 In my free time, I enjoy experimenting with coding techniques and refining my design skills. 🛠️ I'm also passionate about giving back through volunteering and mentoring aspiring developers. 🌟 My goal is to keep growing both personally and professionally, making a positive impact wherever I go. ✨`,
  },
];

export const servicesData: servicesDataType[] = [
  {
    id: 1,
    title: "Website Development",
    img: WebDevImg,
    goToLink: "/services/website-development",
  },
  {
    id: 2,
    title: "Graphic Designing",
    img: GraphicDesignImg,
    goToLink: "/services/graphic-designing",
  },
  {
    id: 3,
    title: "Application Devlopment",
    img: AppDevImg,
    goToLink: "/services/app-development",
  },
  {
    id: 4,
    title: "Digital Marketing",
    img: DigitalMarketingImg,
    goToLink: "/services/digital-marketing",
  },
];

export const ourWorkData: OurWorkType[] = [
  {
    id: 1,
    title: "Admin Dashboard",
    workImg: Dashboard1Img,
    goToLink: "https://vyomscode.com/webdev/admin-dashboard/index.php",
  },
  {
    id: 2,
    title: "User Dashboard",
    workImg: Dashboard2Img,
    goToLink: "https://vyomscode.com/webdev/user-dashboard/index.php",
  },
  {
    id: 3,
    title: "Student Dashboard",
    workImg: Dashboard3Img,
    goToLink: "https://vyomscode.com/webdev/student-dashboard/index.html",
  },
];
