import GoToTopButton from "../../common-components/GoToTopButton";
import GdAboutGraphic from "./graphic-designing-subcomponents/GdAboutGraphic";
import GdGraphicContact from "./graphic-designing-subcomponents/GdGraphicContact";
import GdGraphicProjects from "./graphic-designing-subcomponents/GdGraphicProjects";
import GdPageHome from "./graphic-designing-subcomponents/GdPageHome";

function GraphicDesigning() {
  return (
    <div style={{ backgroundColor: "rgb(184, 216, 226)" }}>
      <GdPageHome />

      <GdAboutGraphic />

      <GdGraphicProjects />

      <GdGraphicContact />
    </div>
  );
}

export default GraphicDesigning;
