import { Link } from "react-router-dom";
import TextUtil from "../../../../assets/project-img/text-util.webp";
import Charity from "../../../../assets/project-img/charity.webp";
import FlipCard from "../../../../assets/project-img/flip-card.webp";
import Hotel from "../../../../assets/project-img/Hotel.webp";
import OnlineEd from "../../../../assets/project-img/online-ed.webp";
import Photography from "../../../../assets/project-img/photography.webp";
import Blog1 from "../../../../assets/project-img/blog1.webp";
import Dashboard1 from "../../../../assets/project-img/dashboard1.webp";
import Dashboard2 from "../../../../assets/project-img/dashboard2.webp";
import Dashboard3 from "../../../../assets/project-img/dashboard3.webp";
import Solar from "../../../../assets/project-img/solor.webp";
import { webWorkData } from "../../../../common/data";
import WebWorkCard from "../../../reusables/WebWorkCard";

function WdWebProjects() {
  return (
    <section id="web-projects">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 ">
            <h3 className="text-center text-light">WEBSITE PROJECTS</h3>
            <p className="text-center text-light mb-4">
              With expertise across multiple frontend and backend frameworks,
              we've successfully delivered diverse projects tailored to various
              industries. From React, Angular, Flask, and Node.js, we're
              equipped to bring your vision to life
            </p>
          </div>

          {webWorkData.map((item) => {
            return (
              <WebWorkCard
                key={item.id}
                webTitle={item.webTitle}
                webImage={item.webImage}
                webDescription={item.webDescription}
                webLink={item.webLink}
              />
            );
          })}

        </div>
      </div>
    </section>
  );
}

export default WdWebProjects;
