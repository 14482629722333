import { Link } from "react-router-dom";

function BlogCard({
  blogLink,
  blogTitle,
  blogImage,
}: {
  blogLink: string;
  blogTitle: string;
  blogImage: string;
}) {
  return (
    <div className="col-md-3 my-2">
      <Link className="nav-link h-100" to={blogLink}>
        <div className="card h-100">
          <div className="card-head">
            <img
              src={`https://vyomscode.com/${blogImage}`}
              className="w-100 rounded-top my-auto"
              alt={blogTitle}
            />
          </div>
          <div className="card-body d-flex align-items-end">
            <h5 className="text-center w-100 text-light mb-0">{blogTitle}</h5>
            <br />
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BlogCard;
