import { Link } from "react-router-dom";

// web work data
import { webWorkData } from "../../../common/data";
import WebWorkCard from "../../reusables/WebWorkCard";

function WebWork() {
  return (
    <section id="web" style={{ backgroundColor: "rgb(8, 64, 83)" }}>
      <div className="container pt-2 pb-5">
        <div className="row justify-content-center">
          <div className="col-12 mb-3">
            <h3 className="text-center text-light">
              <b>Website Devlopment Projets</b>
            </h3>
            <p className="text-light text-center">
              Websites Using HTML, CSS, JS, JQuery, Bootstrap, PHP, Laravel,
              React, Angular
            </p>
            <h6 className="text-center">
              <Link to="/services/website-development" className="">
                <button className="btn btn-warning">Explore Services</button>
              </Link>
            </h6>
          </div>

          {webWorkData.map((item) => {
            return (
              <WebWorkCard
                key={item.id}
                webTitle={item.webTitle}
                webImage={item.webImage}
                webDescription={item.webDescription}
                webLink={item.webLink}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default WebWork;
