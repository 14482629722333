function PrivacyPolicy() {
  return (
    <section id=" " className="bg-light">
      <div
        className="container pt-5 text-dark pb-md-5"
        style={{ maxWidth: "1000px" }}
      >
        <h2 className="color-blue text-center">
          <strong>VyomsCode- Privacy Policy</strong>
        </h2>

        <p className="lh-lg">
          <br />
          <h5 className="mb-0">1. Information Collection</h5>
          Vyomscode may collect personal information from clients, including but
          not limited to name, contact information, and project requirements,
          for the purpose of providing our services.
          <br />
          <br />
          <h5 className="mb-0">2. Use of Information</h5>
          Personal information collected by Vyomscode will be used solely for
          the purpose of fulfilling client requests and providing our services.
          We will not share this information with third parties unless required
          by law or with the explicit consent of the client.
          <br />
          <br /> <h5 className="mb-0">3. Data Security</h5>
          Vyomscode implements measures to safeguard client data against
          unauthorized access, disclosure, alteration, or destruction.
          <br />
          <br /> <h5 className="mb-0">4. Cookies</h5>
          Vyomscode's website may use cookies to enhance user experience.
          Clients can choose to disable cookies in their web browser settings if
          they prefer not to accept them.
          <br />
          <br />
          <h5 className="mb-0">5. Changes to Privacy Policy</h5>
          Vyomscode reserves the right to update or modify this privacy policy
          at any time. Clients will be notified of any changes to the privacy
          policy via email or through our website.
          <br />
          <br /> <h5 className="mb-0">6. Consent to Communication</h5>
          By providing contact information to Vyomscode, clients consent to
          receiving communication related to their project, including but not
          limited to project updates, invoices, and important notifications.
          Clients may opt out of non-essential communications at any time by
          contacting Vyomscode.
          <br />
          <br /> <h5 className="mb-0">7. Data Retention</h5>
          Vyomscode will retain client data for the duration necessary to
          fulfill the purposes outlined in this privacy policy, or as required
          by law. Upon request, clients may inquire about the deletion or
          anonymization of their personal information from Vyomscode's records,
          subject to any legal obligations to retain such data.
          <br />
          <br /> <h5 className="mb-0">8. Third-Party Services</h5>
          Vyomscode may utilize third-party services or software tools to assist
          in project management, communication, or data storage. While Vyomscode
          takes precautions to select reputable service providers, clients
          should review the privacy policies of these third-party services for
          additional information on data handling practices.
          <br />
          <br /> <h5 className="mb-0">9. Confidentiality</h5>
          Vyomscode agrees to maintain the confidentiality of all client
          information and project details disclosed during the course of our
          engagement, except as required by law or with the express consent of
          the client.
          <br />
          <br />
          By agreeing to our terms and conditions and privacy policy, you
          consent to the collection and use of your personal information as
          outlined above.
        </p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
