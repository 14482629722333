import { Link } from "react-router-dom";
import logo from '../../assets/logo.png'

function Header() {
  return (
    <nav className="navbar navbar-expand-lg px-md-4 py-0 sticky-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo" width="80" height="70" />
        </Link>
        <div className="large-hide-call-btn" style={{ marginRight: "-60px" }}>
          <Link className="nav-link" target="_blank" to="tel:7042562687">
            <button className="btn btn-outline-light rounded-pill">
              Call&nbsp;Us
            </button>
          </Link>
        </div>
        <button
          className="navbar-toggler text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-list text-light"></i>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/#about-us">
                About&nbsp;Us
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/work">
                Work
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                target="_blank"
                to="https://blog.vyomscode.com/"
              >
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">
                Contact&nbsp;Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="small-hide-call-btn">
          <Link className="nav-link" target="_blank" to="tel:7042562687">
            <button className="btn btn-outline-light rounded-pill">
              Call&nbsp;Us
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
