function AdAboutApp() {
  return (
    <section id="about-app">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 pt-2 pb-4">
            <h2 className="text-center text-light">Services We Provide</h2>
            <p className="text-light text-center">
              VyomsCode: Your One-Stop Solution for Comprehensive App
              Development Services.
            </p>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Custom Mobile App Development
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Crafting bespoke mobile applications tailored to your unique
                  requirements and business objectives.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Cross-Platform Development
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Building apps that run seamlessly on multiple platforms,
                  ensuring wider reach and accessibility.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Native App Development
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Developing high-performance native apps for iOS and Android
                  platforms, leveraging platform-specific capabilities.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">UI/UX Design</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Creating intuitive and visually appealing user interfaces to
                  enhance user experience and engagement.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">Backend Development</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Building robust backend systems to support app
                  functionalities, ensuring scalability and reliability.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">API Integration</h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Seamlessly integrating third-party APIs and services to
                  enhance app functionality and user experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">
                  Quality Assurance Testing
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Conducting rigorous testing to identify and fix bugs, ensuring
                  the app's reliability and performance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card h-100 bg-purple">
              <div className="card-header">
                <h5 className="text-light text-center">
                  App Maintenance and Support
                </h5>
              </div>
              <div className="card-body">
                <p className="text-light">
                  Providing ongoing maintenance and support services to ensure
                  your app remains up-to-date and functional post-launch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdAboutApp;
