function TermsAndConditions() {
  return (
    <section id=" " className="bg-light">
      <div
        className="container pt-5 text-dark pb-md-5"
        style={{ maxWidth: "1000px" }}
      >
        <h2 className="color-blue text-center">
          <strong>VyomsCode- Terms and Conditions</strong>
        </h2>

        <p className="lh-lg">
          <br /> <h5 className="mb-0"> 1. Introduction</h5>
          These terms and conditions govern your use of the services provided by
          Vyomscode. By accessing or using our services, you agree to be bound
          by these terms and conditions. If you disagree with any part of these
          terms and conditions, you may not access or use our services.
          <br />
          <br /> <h5 className="mb-0"> 2. Service Description</h5>
          Vyomscode provides software development services to clients, including
          but not limited to website development, software application
          development, and related services. Prior to initiating any project,
          Vyomscode will conduct meetings, either physical or virtual, with
          clients to discuss project requirements and scope.
          <br />
          <br /> <h5 className="mb-0"> 3. Contract Formation</h5>
          Following the initial discussion, Vyomscode will create a contract
          outlining the project details, including functionalities, payment
          amount, payment schedule, and project delivery date. This contract
          will be signed by both the client and a designated representative from
          Vyomscode.
          <br />
          <br /> <h5 className="mb-0"> 4. Payment and Renewal</h5>
          Clients are required to adhere to the payment schedule outlined in the
          contract. Failure to make payments as agreed upon may result in
          project delays or termination of services. Additionally, clients will
          be charged yearly for renewal and maintenance fees as specified in the
          contract. Renewal of services can be terminated by the client upon
          written notice to Vyomscode. Vyomscode will contact clients at least
          10 days prior to the renewal date to discuss renewal options and
          confirm client preferences.
          <br />
          <br /> <h5 className="mb-0"> 5. Data Security</h5>
          Vyomscode takes the security of client data seriously. All data
          provided to Vyomscode will be securely stored and protected from
          unauthorized access or disclosure.
          <br />
          <br /> <h5 className="mb-0"> 6. Recordkeeping</h5>
          All payment dates, amounts, and project scope changes will be
          accurately recorded by Vyomscode. These records will serve as an
          official reference for both parties throughout the duration of the
          project. Clients may request access to these records at any time for
          verification purposes.
          <br />
          <br /> <h5 className="mb-0"> 7. Liability</h5>
          In the event of payment failure or project failure resulting from
          client actions or decisions, Vyomscode reserves the right to take
          appropriate actions to rectify the situation, which may include legal
          proceedings if necessary.
          <br />
          <br /> <h5 className="mb-0"> 8. Communication and Instructions</h5>
          Clients are responsible for providing clear and accurate instructions
          to Vyomscode regarding project requirements, functionalities, and any
          changes throughout the project lifecycle. Vyomscode will communicate
          with clients via email, phone, or in-person meetings as necessary to
          ensure mutual understanding and agreement on project scope and
          deliverables.
          <br />
          <br /> <h5 className="mb-0"> 9. Project Scope Changes</h5>
          Any changes to the project scope or requirements must be communicated
          and agreed upon by both parties in writing. Vyomscode reserves the
          right to adjust project timelines and costs accordingly for any
          approved scope changes.
          <br />
          <br /> <h5 className="mb-0"> 10. Intellectual Property Rights</h5>
          Upon full payment and completion of the project, all intellectual
          property rights to the developed software and website functionalities
          will be transferred to the client, unless otherwise specified in the
          contract. Vyomscode retains the right to showcase the project in its
          portfolio and marketing materials unless the client requests otherwise
          in writing.
          <br />
          <br /> These terms and conditions shall be governed by and construed
          in accordance with the laws of [Your Jurisdiction], and any disputes
          relating to these terms and conditions will be subject to the
          exclusive jurisdiction of the courts of [Your Jurisdiction].
          <br />
        </p>
      </div>
    </section>
  );
}

export default TermsAndConditions;
