function TestimonialCarousel({
  id,
  personName,
  personCompany,
  personTitle,
  testimonialText,
}: {
  id: number;
  personName: string;
  personCompany: string;
  personTitle: string;
  testimonialText: string;
}) {
  return (
    <div
      className={
        id === 1 ? "carousel-item h-100 active" : "carousel-item h-100"
      }
    >
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-md-10 col-sm-12">
          <p className="lh-lg text-center ">
            {testimonialText}
            <br />
            <strong>{personName}</strong>
            <br />
            <b>({`${personCompany} - ${personTitle}`})</b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCarousel;
