import MpAboutUs from "./main-page-components/MpAboutUs";
import MpBlog from "./main-page-components/MpBlog";
import MpHowItWorks from "./main-page-components/MpHowItWorks";
import MpOurWork from "./main-page-components/MpOurWork";
import MpPageHome from "./main-page-components/MpPageHome";
import MpServices from "./main-page-components/MpServices";
import MpShowCase from "./main-page-components/MpShowCase";
import MpTestimonials from "./main-page-components/MpTestimonials";

function MainPage() {
  return (
    <div>
      <MpPageHome />

      <MpShowCase />

      <MpAboutUs />

      <MpServices />

      <MpOurWork />

      <MpHowItWorks />

      <MpBlog />

      <MpTestimonials />

      <section id="fandq"></section>
    </div>
  );
}

export default MainPage;
